import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fcommon%2FCartPill%2FCartPill.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81XyW7jMAy95ysEDAokQFV4idPUvcxp%2FkO2aVuNLHkkZeug%2Fz6w5E2JkzRAD%2BkhhURSIvnIJ%2FpFrotPrTz0b4bQJ6Y8g0OMfM%2Fz3mcI1UJRTQWPkQRGNN3B%2B%2Bxr9mJtfGNTkyyjvIiRv6oPjc2eZro0RzyNlAOjnFFVM3KMUc7AKH9slab5EaeCa%2BA6RqomKeAE9B6ANxqE0YJjqqFSMUqBa5Dv3782NNdWRBaUx8hDOyLnGG9XRLMyWLz3MqxFHTtSfzE6ZmmOyQXXOCcVZUdXN9ibo4x8D7QodYxWNoNmT%2BkjgxOT42Ci6Oep9NNIGeWAy%2FbAsTz0Rr7HyBu5Gj2mq6lgQp5ImJFoOGhsUB7jO4blzSLcbiVCa1HFKIia7T7u1Y24q%2FO4HTlfXM2BuJqD%2BnoOgr%2BLsxIdlfBrfUC%2BhGoUzauJJt1K1WStFtTmZSRXuwLVRJdGUUvCu071XgKFgChAhLGxSVyKHUjXMKeMub4uuS38lEiNGeUbTFPBcUKyAoYU2xz4nkXGCdxfXSqwXmPttmy7HMiGJEqwrQZTHk0FYN9vb4K8OSCyq4GvmlVC0k0hxZZneKLalspgkAiZgZxUqMYKnW%2F2onZTkoxuVYyWbdxndHaBrM5YrhP14KxvoGG13qZJtPmPMyohbbla7M%2BcMTpKE6kbUUHqLov94cQc3sa9DGyEHWRhcIrR8CC0DqhSUr5xySh5TDKajsJWWmgDxXtINtR0AOCUkaqOUdAImh7KmdjHqKRZZt%2BoHpHOKhHOIYk4YCGpgX4HUtOUsAuk2FbhFLOn0%2BhfqDkD8cphThNg4ICeXQco9K%2BzZhhcZc0wvAZWuLwBVnTx5ajGXQH3x3CjyH7A7967%2FPEzbF7gDFIhie0KY6BLKbZF%2BS0I7GMytJXSNN0cLXPa19q7Y2ij9xf6enJE8EOn2D%2FuHxFusdFPjAWt09IqrB2XN4%2Focu8du5%2FeHfnh%2Bsz1E1R%2Fo7b7UKqTCp743uHtt8Shv28ZtYPI8CiQrRbNjkqlYAwnUJIdbZpHVULochAlpB8yuODOPXHcPRy9qtsRJwbCSIfxxwZJJC6agQW4nnsZFM%2Fo1x%2Fzh7ynZySLhMyDKHpGw4%2B3QJH3tHBGNc%2Bb6NRLs5rb7HZoxbADrlXn9He%2BAYfePh9hgGejyOuLqA3OdEOqmZFzIatmQNJEw%2FytSYup598VZJSgeQNuG%2Bk6XNaHhTn%2F8izcDQ0tnfXzqd%2Bt%2BzxG3U6XyHbja%2Fb1H9C7wCODDwAA%22%7D"
export var cartActiveLink = 'r8gzts8';
export var cartBtn = 'r8gzts6';
export var cartEmptyText = 'r8gzts5';
export var cartEmptyWrap = 'r8gzts1';
export var cartHeaderWrap = 'r8gzts2';
export var cartHeading = 'r8gzts4';
export var cartItemLink = 'r8gzts7';
export var cartItemsScrollWrap = 'r8gztsm';
export var cartItemsWrap = 'r8gztsn';
export var cartPadding = 'r8gzts1';
export var cartPill = 'r8gzts0';
export var cartPillDropdown = 'r8gzts3';
export var cartScrollArrow = 'r8gztsp';
export var cartScrollOverlay = 'r8gztso';
export var creator = 'r8gztsd';
export var cutPrice = 'r8gztsf';
export var footerPriceContainer = 'r8gztsi';
export var footerWrap = 'r8gztsh';
export var price = 'r8gztse';
export var productDetails = 'r8gztsc';
export var productImageContainer = 'r8gztsa';
export var productInfoWrapper = 'r8gzts9';
export var productTitle = 'r8gztsb';
export var totalCutPrice = 'r8gztsl';
export var totalPrice = 'r8gztsk';
export var totalText = 'r8gztsj';