import React, { useState, useRef, Fragment, useEffect } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { ProductData, PurchaseableItem } from "data/product/products";
import { Button, RightChevronIcon } from "studio-design-system";
import { useWindowSize } from "usehooks-ts";

import { ArrowRightIcon } from "~/components/cart/Icons/ArrowRightIcon";
import { useAuth } from "~/hooks/auth/useAuth";
import { useCart } from "~/hooks/cart/useCart";
import { usePriceOfferInfoCheckout } from "~/hooks/usePriceOfferInfo";
import { getResponsiveImageURL } from "~/util/cloudinary-utils";
import { isScrollAvailable } from "~/util/sizeUtils";
import { formatMoney, groupedPrice } from "~/util/stringUtils";

import { CartIcon } from "../Icons/CartIcon";
import { ImageWithBlur } from "../ImageWithBlur/ImageWithBlur";
import { CartMenu, CartMenuSeparator } from "./CartMenu";
import * as styles from "./CartPill.css";

interface Props {
  iconSize?: number;
}

export const CartPill = ({ iconSize = 24 }: Props): React.ReactElement => {
  const [isFlyCartOpen, setIsFlyCartOpen] = useState(false);
  const cartIconRef = useRef(null);
  const { cartItems, getCartPathWithQuery } = useCart();
  const router = useRouter();
  const cartWrapRef = useRef<HTMLDivElement>(null);
  const [scrollAvailable, setScrollAvailable] = useState(false);
  const screen = useWindowSize();
  const { user } = useAuth();
  const { shouldUserSeeOfferCalculation, getProductPrice } = usePriceOfferInfoCheckout();
  const [shouldShowPriceOffer, setShouldShowPriceOffer] = useState(false);

  const toggleFlyCart = () => {
    if (screen.width < 834) {
      return false;
    }
    if (router.pathname !== "/" || cartItems.length > 0) {
      setIsFlyCartOpen((current) => !current);
      cartWrapRef.current?.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const shouldUserSeeSomeOffer = cartItems.some((item) => {
      const shouldUserSeeOffer = shouldUserSeeOfferCalculation(item.priceInfo);
      return shouldUserSeeOffer;
    });
    setShouldShowPriceOffer(shouldUserSeeSomeOffer);
  }, [user, cartItems, shouldUserSeeOfferCalculation]);

  useEffect(() => {
    setScrollAvailable(isScrollAvailable(cartWrapRef.current));
  }, [screen.width, screen.height]);

  return (
    <div className={styles.cartPill} ref={cartIconRef}>
      <div
        className={styles.cartPillDropdown}
        onMouseEnter={toggleFlyCart}
        onMouseLeave={() => setIsFlyCartOpen(false)}
      >
        <span className={styles.cartItemLink} onClick={() => router.push(getCartPathWithQuery())}>
          <CartIcon size={iconSize} badge={cartItems.length} />
        </span>
        <CartMenu
          isOpen={isFlyCartOpen}
          onOutsideClick={() => setIsFlyCartOpen(false)}
          exceptions={[cartIconRef]}
        >
          {cartItems.length > 0 ? (
            <>
              <div className={styles.cartHeaderWrap}>
                <h4 className={styles.cartHeading}>Shopping Cart</h4>
                <Link href={getCartPathWithQuery()} passHref>
                  <a>
                    <ArrowRightIcon />
                  </a>
                </Link>
              </div>
              <CartMenuSeparator />
              <div className={styles.cartItemsScrollWrap}>
                <div
                  ref={cartWrapRef}
                  onScroll={() => {
                    setScrollAvailable(isScrollAvailable(cartWrapRef.current));
                  }}
                  className={styles.cartItemsWrap}
                >
                  {cartItems.map((product: PurchaseableItem, index: number) => {
                    const isPurchaseableItemAlwaysOnSale = "availableUntil" in product;
                    const shouldUserSeeOffer = shouldUserSeeOfferCalculation(product.priceInfo);

                    return (
                      <Fragment key={`cart_item_${index}`}>
                        <div className={styles.cartPadding}>
                          <div className={styles.productInfoWrapper}>
                            <div className={styles.productImageContainer}>
                              <ImageWithBlur
                                style={{ borderRadius: "4px" }}
                                src={getResponsiveImageURL(product.productImageURL, "500")}
                                alt={product.title}
                                layout="fill"
                                objectFit="cover"
                              />
                            </div>
                            <div>
                              <p className={styles.productTitle}>{product.title}</p>
                              <div className={styles.productDetails}>
                                <span className={styles.creator}>
                                  {product.creatorName} &nbsp;|
                                </span>

                                <span className={styles.price}>
                                  {formatMoney(
                                    getProductPrice(product.priceInfo, shouldUserSeeOffer),
                                  )}
                                </span>
                                {shouldUserSeeOffer && (
                                  <span className={styles.cutPrice}>
                                    {formatMoney(product.priceInfo.crossedPrice)}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <CartMenuSeparator />
                      </Fragment>
                    );
                  })}
                </div>
                {scrollAvailable && (
                  <div className={styles.cartScrollOverlay}>
                    <RightChevronIcon
                      className={styles.cartScrollArrow}
                      width={10}
                      height={10}
                      fill="black"
                    />
                  </div>
                )}
              </div>
              <div className={styles.footerWrap}>
                <div className={styles.footerPriceContainer}>
                  <span className={styles.totalPrice}>
                    <span className={styles.totalText}>Total:</span>{" "}
                    {groupedPrice("price", cartItems, shouldUserSeeOfferCalculation)} USD
                  </span>
                  {shouldShowPriceOffer && (
                    <span className={styles.totalCutPrice}>
                      {groupedPrice("crossedPrice", cartItems, shouldUserSeeOfferCalculation)}
                    </span>
                  )}
                </div>
                {router.pathname != "/cart" ? (
                  <Button
                    onClick={() => router.push(getCartPathWithQuery())}
                    className={styles.cartBtn}
                  >
                    Go to cart
                  </Button>
                ) : (
                  <Button onClick={() => router.push("/checkout")} className={styles.cartBtn}>
                    Checkout
                  </Button>
                )}
              </div>
            </>
          ) : (
            <div className={styles.cartEmptyWrap}>
              <p className={styles.cartEmptyText}>Your cart is empty!</p>
              <Button onClick={() => router.push("/")} className={styles.cartBtn}>
                Keep browsing
              </Button>
            </div>
          )}
        </CartMenu>
      </div>
    </div>
  );
};
