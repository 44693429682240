import React from "react";

export const CartIcon = ({ badge = 0, size = 24 }) => {
  return (
    <div style={{position: "relative"}}>
      {badge > 0 && 
        <span className="cart-link-icon-badge">{badge}</span>
      }
      <svg style={{position: "relative" }} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.142 13.1654C18.017 13.1654 18.787 12.687 19.1837 11.9637L23.3603 4.39203C23.792 3.62203 23.232 2.66536 22.3453 2.66536H5.07866L3.98199 0.332031H0.166992V2.66536H2.50033L6.70033 11.5204L5.12533 14.367C4.27366 15.9304 5.39366 17.832 7.16699 17.832H21.167V15.4987H7.16699L8.45033 13.1654H17.142ZM6.18699 4.9987H20.362L17.142 10.832H8.95199L6.18699 4.9987ZM7.16699 18.9987C5.88366 18.9987 4.84533 20.0487 4.84533 21.332C4.84533 22.6154 5.88366 23.6654 7.16699 23.6654C8.45033 23.6654 9.50033 22.6154 9.50033 21.332C9.50033 20.0487 8.45033 18.9987 7.16699 18.9987ZM18.8337 18.9987C17.5503 18.9987 16.512 20.0487 16.512 21.332C16.512 22.6154 17.5503 23.6654 18.8337 23.6654C20.117 23.6654 21.167 22.6154 21.167 21.332C21.167 20.0487 20.117 18.9987 18.8337 18.9987Z" fill="#AFAFAF"/>
      </svg>
    </div>
  );
};
