import React from "react";

import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

import { ClickOuterWrapper } from "studio-design-system";

import * as styles from "./CartMenu.css";

interface Props {
  isOpen: boolean;
  exceptions: React.MutableRefObject<null>[];
  children?: React.ReactNode;
  onOutsideClick: () => void;
}

export const CartMenu = ({
  isOpen,
  children,
  exceptions,
  onOutsideClick,
}: Props): React.ReactElement => (
  <ClickOuterWrapper isOpen={isOpen} onOutsideClick={onOutsideClick} exceptions={exceptions}>
    <div className={classNames([styles.flyCartBase, isOpen && styles.flyCartOpen])}>{children}</div>
  </ClickOuterWrapper>
);

export const CartMenuSeparator = (): React.ReactElement => (
  <div className={styles.flyCartSeparator} />
);
