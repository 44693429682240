import Link from "next/link";

import { classesCategories, classesTabs, sortedSearchOptions } from "data/classesTags/classesTags";

import { SearchBar } from "../../Search";
import * as styles from "./SearchModal.css";
import { ArrowRight } from "./icons";

export const SearchModal = ({ handleCloseModal }: { handleCloseModal: () => void }) => {
  const ClassLink = ({ label, href, order }: { label: string; href: string; order: number }) => (
    <Link href={href} passHref>
      <p
        className={styles.linkContainer}
        onClick={handleCloseModal}
        style={{
          order: order,
        }}
      >
        {label}
        <ArrowRight />
      </p>
    </Link>
  );

  return (
    <div>
      <SearchBar handleCloseModal={handleCloseModal} />
      <p className={styles.description}>
        <strong>Search for</strong> topics, classes, genres, and instructors
      </p>
      <div className={styles.classesContainer}>
        <ClassLink label={"All classes"} href="/browse" order={1} />
        {classesTabs.map((tab, index) => (
          <ClassLink
            key={index}
            label={tab}
            href={`/browse?view=${tab}`}
            order={sortedSearchOptions.indexOf(tab) + 1}
          />
        ))}
        {classesCategories.map((category, index) => (
          <ClassLink
            key={index}
            label={category}
            href={`/browse?category=${category}`}
            order={sortedSearchOptions.indexOf(category) + 1}
          />
        ))}
      </div>
    </div>
  );
};
